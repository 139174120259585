<template>
  <div class="h-100 z-10 portraitAlertWrapper" style="width:100%;">
    <div
      class="position-absolute d-flex flex-column justify-content-center align-items-center p-4 w-100 h-100 text-white text-center"
      style="p-4">
      <inline-svg src="images/icons/rotate-screen.svg" class="mb-3 mt-auto" aria-label="Rotate screen"
        style="width:40%;" />

      <h1 class="font-rem-1-25 font-w-600 textPortraitAlert mt-3" style="opacity:1; width:65%;">
        Please rotate your screen to continue
      </h1>

      <inline-svg src="images/triumf-logo.svg" class="mt-auto mb-3" aria-label="TRIUMF LOGO"
        style="width:40%;max-width:300px;" />
    </div>
  </div>
</template>

<script>
import anime from "animejs"
export default {
  computed: {},
  mounted() {
    this.startLoop()
  },
  methods: {
    startLoop() {
      /* anime({
        loop: true,
        targets: ".lns4m",
        translateX: ["0%", "-40%"],
        direction: "alternate",
        duration: 12000,
        easing: "easeInOutSine"
      }) */
    },
    slide0(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 500
      })

      animation
        .add({
          targets: ".fadePortraitAlert",

          translateY: ["-30%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 5000 + 30 * i
        })
        .add({
          targets: ".textPortraitAlert",

          opacity: [0, 1],

          translateY: ["35%", "0%"],
          duration: 100,
          easing: "easeInOutSine"
        })
      var progress = percentage <= 0.5 ? percentage * 2 : 2
      animation.seek(animation.duration * progress)
    }
  },
  watch: {}
}
</script>
<style lang="scss" scoped></style>
